import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { alpha, useTheme } from '@mui/material/styles';
import Logo from 'src/components/logo';
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
import { shadows } from 'src/theme/shadows';
import { HEADER } from '../config-layout';
import { navConfig } from './config-navigation';
import NavDesktop from './nav/desktop';
import NavMobile from './nav/mobile/nav-mobile';
import LanguagePopover from '../LanguagePopover';
//

// ----------------------------------------------------------------------

export default function Header() {
  const theme = useTheme();

  const mdUp = useResponsive('up', 'md');

  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);

  return (
    <Stack
      className="complex-gradient-border"
      sx={{
        position: 'fixed',
        top: 36,
        alignSelf: 'center',
        borderRadius: 999,
        background: alpha('#FFF', 0.6),
        backdropFilter: 'blur(15px)',
        transition: theme.transitions.create(['height'], {
          easing: theme.transitions.easing.easeInOut,
          duration: theme.transitions.duration.shorter,
        }),
        zIndex: 999,
      }}
      boxShadow={shadows}
    >
      <Stack className="content" pl={4} pr={2} py={1} direction="row" alignItems="center">
        <Logo sx={{ height: 25 }} />

        <Box flex={1} width={120} />

        {mdUp && <NavDesktop offsetTop={offsetTop} data={navConfig} />}

        <Stack px={2}>
          <LanguagePopover />
        </Stack>

        {!mdUp && <NavMobile offsetTop={offsetTop} data={navConfig} />}
      </Stack>
    </Stack>
  );
}
