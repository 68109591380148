// routes
// config
// components
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export const navConfig = [
  {
    title: '213',
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: '/',
  },
  {
    title: '207',
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: '/about-us',
  },
  {
    title: '202',
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: '/forex',
  },
  {
    title: '204',
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: '/crypto',
  },
  {
    title: '201',
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: '/contact-us',
  },
];
