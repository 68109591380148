import { enUS as enUSCore, koKR as koKRCore, viVN as viVNCore } from '@mui/material/locale';
import { enUS as enUSDataGrid, koKR as koKRDataGrid, viVN as viVNDataGrid } from '@mui/x-data-grid';
import { enUS as enUSDate, koKR as koKRDate, viVN as viVNDate } from '@mui/x-date-pickers/locales';
import { enUS as enUSAdapter, ko as koKRAdapter, vi as viVNAdapter } from 'date-fns/locale';
import merge from 'lodash/merge';

// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: merge(enUSDate, enUSDataGrid, enUSCore),
    adapterLocale: enUSAdapter,
    icon: 'flagpack:gb-nir',
  },
  {
    label: 'Korean',
    value: 'ko',
    systemValue: merge(koKRDate, koKRDataGrid, koKRCore),
    adapterLocale: koKRAdapter,
    icon: 'flagpack:kr',
  },
  {
    label: 'Vietnamese',
    value: 'vi',
    systemValue: merge(viVNDate, viVNDataGrid, viVNCore),
    adapterLocale: viVNAdapter,
    icon: 'flagpack:vn',
  },
];

export const defaultLang = allLangs[0]; // English
