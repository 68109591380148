import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Iconify from 'src/components/iconify';
import Logo from 'src/components/logo';
import { useLocales } from 'src/locales';
import { RouterLink } from 'src/routes/components';
import LanguagePopover from '../LanguagePopover';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: '203',
    children: [
      { name: '202', href: '/forex' },
      { name: '204', href: '/crypto' },
      { name: '205', href: '#' },
    ],
  },
  {
    headline: '206',
    children: [
      { name: '207', href: '/about-us' },
      { name: '208', href: '/contact-us' },
    ],
  },
];

// ----------------------------------------------------------------------

export default function Footer() {
  const { t } = useLocales();

  const mainFooter = (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: 'background.default',
        borderTopLeftRadius: 10,
      }}
    >
      <Divider />

      <Container
        sx={{
          pt: 10,
          pb: 5,
          textAlign: { xs: 'center', md: 'unset' },
        }}
      >
        <Grid
          container
          justifyContent={{
            xs: 'center',
            md: 'space-between',
          }}
          spacing={6}
        >
          <Grid xs={8} md={3}>
            <Logo />
          </Grid>

          <Grid xs={12} md={6}>
            <Stack spacing={5} direction={{ xs: 'column', md: 'row' }}>
              {LINKS.map((list) => (
                <Stack
                  key={list.headline}
                  spacing={2}
                  alignItems={{ xs: 'center', md: 'flex-start' }}
                  sx={{ width: 1 }}
                >
                  <Typography
                    component="div"
                    variant="overline"
                    textTransform="capitalize"
                    fontWeight="600"
                    fontSize={15}
                  >
                    {t(list.headline)}
                  </Typography>

                  {list.children.map((link) => (
                    <Link
                      key={link.name}
                      component={RouterLink}
                      href={link.href}
                      color="inherit"
                      variant="body2"
                    >
                      {t(link.name)}
                    </Link>
                  ))}
                </Stack>
              ))}

              <Stack spacing={2} alignItems={{ xs: 'center', md: 'flex-start' }} sx={{ width: 1 }}>
                <Typography component="div" variant="overline" fontWeight="600" fontSize={15}>
                  {t('209')}
                </Typography>

                <Stack direction="row" alignItems="center" spacing={2}>
                  <IconButton
                    className="srlabs-card"
                    onClick={() =>
                      window.open('https://www.linkedin.com/company/srlabsofficial/', '_blank')
                    }
                  >
                    <Iconify icon="ant-design:linkedin-outlined" />
                  </IconButton>

                  <IconButton
                    className="srlabs-card"
                    onClick={() =>
                      window.open('https://www.facebook.com/saigonriverlabs/', '_blank')
                    }
                  >
                    <Iconify icon="iconoir:facebook" />
                  </IconButton>

                  <IconButton
                    className="srlabs-card"
                    onClick={() => window.open('https://t.me/srlabs_bd', '_blank')}
                  >
                    <Iconify icon="uit:telegram-alt" />
                  </IconButton>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        </Grid>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mt: 10 }}>
          <Typography variant="body2">{t('210')}</Typography>

          <Stack direction="row" alignItems="center" spacing={3}>
            <Link component={RouterLink} href="#" color="inherit" variant="body2">
              {t('211')}
            </Link>

            <Link component={RouterLink} href="#" color="inherit" variant="body2">
              {t('212')}
            </Link>

            <LanguagePopover />
          </Stack>
        </Stack>
      </Container>
    </Box>
  );

  return mainFooter;
}
